<template>
  <div class="authSuccess">
    <AppHeader :type="1"></AppHeader>
    <div class="warp"
         style="min-height:calc(100% - 165px)">
      <div class="content">
        <img class="topImg"
             src="@/assets/examine.png"
             alt="">
        <div class="right">
          <img src="@/assets/right.png" />
        </div>
        <p class="text">企业身份认证信息提交成功，人工审核通过后将通知您</p>
        <div class="footBtn">
          <el-button type="primary"
                     @click="onSubmit">查看接入流程</el-button>
        </div>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>
<script>
import AppHeader from '@/components/layer/AppHeader'
import AppFooter from '@/components/layer/AppFooter'
import { setInterval, clearInterval } from 'timers'

export default {
  components: {
    AppHeader,
    AppFooter
  },
  data() {
    return {
      inter: 60,
      isInter: false
    }
  },
  methods: {
    onSubmit() {
      this.$router.push({ path: '/hashcertificate/overview' })
    }
  }
}
</script>
<style lang="scss" scoped>
  .authSuccess {
    height: 100%;
    .content {
      width: 1000px;
      height: 672px;
      box-shadow: 0px 14px 13px 0px rgba(186, 191, 198, 0.1);
      margin: 0 auto;
      padding-top: 85px;
      .right {
        text-align: center;
        margin: 96px 0 38px;
      }
      .text {
        margin: 0 0 149px;
        text-align: center;
        color: #293752;
        font-weight: bold;
      }
      .topImg {
        width: 100%;
        height: 157px;
      }
      .footBtn {
        width: 100%;
        text-align: center;
        button {
          width: 160px;
          height: 50px;
          font-size: 18px;
          color: #fff;
          background: linear-gradient(
            -90deg,
            rgba(49, 97, 187, 1) 0%,
            rgba(42, 72, 156, 1) 100%
          );
          border-radius: 4px;
          border: none;
        }
      }
    }
  }
</style>
